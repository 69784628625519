import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TbWorld } from "react-icons/tb";
import { LanguageContext } from "../../context/languagecontext";
// Assuming 'lang' is a string representing the default language code

const NavMenuItems = ({ menus }) => {
  const { lang, setLang, getTranslation } = useContext(LanguageContext);

    const handleLanguageChange = (event) => {
        setLang(event);
    };

  return (
    <>
      {menus.length > 0 &&
        menus.map((menu) => (
          <li key={menu.id}>
            <Link to={menu.link}>
              {menu.text}{" "}
              {menu.dropdown.length > 0 && <i className="fa fa-angle-down" />
              
              }
            </Link>

            {menu.dropdown.length > 0 && (
              <ul>
                {menu.dropdown.map((dropdownMenu) => (
                  <li key={dropdownMenu.id}>
                    <Link to={dropdownMenu.link}>{dropdownMenu.text}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}

      <div className="btn-group">
        <button
          type="button"
          className="btn btn-transparent dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <TbWorld color="#c9ac52" fontSize="2em" />
        </button>
        <div className="dropdown-menu">
          <a
            className="dropdown-item"
            href="#"
            onClick={() => {
              handleLanguageChange("en");
            }}
          >
         {getTranslation("English")}   
          </a>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => {
              handleLanguageChange("ar");
            }}
          >
            {getTranslation("Arabic")}     
          </a>
        </div>
      </div>

      {/* Display content based on the selected language */}
    </>
  );
};

export default NavMenuItems;
