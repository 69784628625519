import React, { useContext } from "react";
import { LanguageContext } from "../../context/languagecontext";

function TestimonialData() {
    const { getTranslation} = useContext(LanguageContext);
  const data = [
    {
      id: 1,
      content: getTranslation("subj1"),
      name: "David Max",
      image: "testimonial1.jpg",
      designation: "Ceo & Founder",
    },
    {
      id: 2,
      content: getTranslation("subj1"),
      name: "David Max",
      image: "testimonial2.jpg",
      designation: "Ceo & Founder",
    },
    {
      id: 3,
      content: getTranslation("subj1"),
      name: "David Max",
      image: "testimonial2.jpg",
      designation: "Ceo & Founder",
    },
  ];
  return data;
}

export default TestimonialData;
