import React, { useContext } from "react";
import PostTypeOne from "../post/PostTypeOne";
import SectionTitle from "../section-title/SectionTitle";
import UsePostTypeOneData from "../post/PostTypeOneData";
import { LanguageContext } from "../../context/languagecontext";

const HomePost = () => {
    const data =  UsePostTypeOneData()
    const Data = data.slice(0, 3)
    const {getTranslation} = useContext(LanguageContext)
    return (
        <>
            {/* post Section Start */}
            <div className="blog-sec pt-100 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <SectionTitle
                                title={getTranslation("Recent News")}
                                content={getTranslation("newsparagraph")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {
                            Data.map((item) => (
                                <PostTypeOne
                                    key={item.id}
                                    data={item}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* post Section End */}
        </>
    );
}

export default HomePost;