import React, { useContext } from "react";
import TeamMembersCard from "./TeamMembersCard";
import SectionTitle from "../section-title/SectionTitle";
import { LanguageContext } from "../../context/languagecontext";
import TeamMembersCardData from "./TeamMembersCardData";

const TeamMembers = () => {
  const data =  TeamMembersCardData()
    const { getTranslation } =  useContext(LanguageContext)

    return (
        <>
            {/* Team Section Start */}
            <div className="team-sec pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <SectionTitle
                                title={getTranslation("Our Expert Employees")}
                                content={getTranslation("expertparagraph")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {
                            data.map((item) => (
                            <TeamMembersCard
                             key={item.id}
                             data={item}
                            />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* Team Section End */}
        </>
    );
}

export default TeamMembers;