import React, { useContext } from "react";
import { LanguageContext } from "../../context/languagecontext";

const WhyChooseUsCardData = () => {
  const { getTranslation, lang, setLang } = useContext(LanguageContext);

  const Data = [
    {
      id: 1,
      icon: "customer.png",
      title: getTranslation("24/7 Support"),
      desc: getTranslation("Simply dummy text"),
    },
    {
      id: 2,
      icon: "employee.png",
      title: getTranslation("Expert Employees"),
      desc: getTranslation("Simply dummy text"),
    },
    {
      id: 3,
      icon: "locked.png",
      title: getTranslation("Verified Service"),
      desc: getTranslation("Simply dummy text"),
    },
    {
      id: 4,
      icon: "lock.png",
      title: getTranslation("Secured Service"),
      desc: getTranslation("Simply dummy text"),
    },
  ];

  return Data; // returning data from the component
};

export default WhyChooseUsCardData;
