import React, { useContext } from "react";
import { Link } from "react-router-dom";
import FooterServiceNav from "./FooterServiceNav";
import ServicesOneData from "../../components/services/services-one/ServicesOneData";
import { LanguageContext } from "../../context/languagecontext";

const Footer = () => {
  const data = ServicesOneData();
  const { getTranslation } = useContext(LanguageContext);
  return (
    <>
      {/* footer Section Start */}
      <footer>
        {/* footer Top Section Start */}
        <div className="footer-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-wedget-one">
                  <Link to="/">
                    <img src={`/images/logo.png`} alt="Logo" />
                  </Link>
                  <p>{getTranslation("text_footer")}</p>
                  <div className="footer-social-profile">
                    <ul>
                      <li>
                        <a href="">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-pinterest" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-google-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-vimeo" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-widget-menu">
                  <h2>{getTranslation("Service links")}</h2>
                  <ul>
                    {data.map((item) => (
                      <FooterServiceNav key={item.id} data={item} />
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-widget-menu">
                  <h2> {getTranslation("Support links")}</h2>
                  <ul>
                    <li>
                      <a href="#">{getTranslation("Support links")}</a>
                    </li>
                    <li>
                      <a href="#">{getTranslation("title2")}</a>
                    </li>
                    <li>
                      <a href="#">{getTranslation("About Us")}</a>
                    </li>
                    <li>
                      <a href="#">{getTranslation("Create Account")}</a>
                    </li>
                    <li>
                      <a href="#">{getTranslation("title3")}</a>
                    </li>
                    <li>
                      <a href="#">{getTranslation("Contact Us")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-wedget-four">
                  <h2>{getTranslation("Contact Us")} </h2>
                  <div className="inner-box">
                    <div className="inner-item">
                      <div className="media">
                        <div className="media-left">
                          <span className="icon">
                            <i className="fa fa-map-marker" />
                          </span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            {getTranslation("adress_footer")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="inner-item">
                      <div className="media">
                        <div className="media-left">
                          <span className="icon">
                            <i className="fa fa-envelope-o" />
                          </span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            yourmail@gmail.com, info@securepress.com
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="inner-item">
                      <div className="media">
                        <div className="media-left">
                          <span className="icon">
                            <i className="fa fa-phone" />
                          </span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            +880 256794, 24-2564687
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer Top Section End */}
        {/* footer Bottom Section Start */}
        <div className="footer-bottom-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copy-right">
                  <p>
                   {getTranslation("foot")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer Bottom Section End */}
      </footer>
      {/* footer Section End */}
    </>
  );
};

export default Footer;
