// LanguageContext.js

import React, { createContext, useState, useEffect } from "react";
import en from "../Locale/en.json";
import ar from "../Locale/ar.json";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  // Initialize lang state with the value from localStorage or default to 'ar'
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("lang");
    return storedLang || "ar";
  });

  useEffect(() => {
    // Update localStorage with the current language whenever it changes
    localStorage.setItem("lang", lang);
  }, [lang]);

  const getTranslation = (key) => {
    const translations = { en, ar };
    return (
      translations[lang][key] ||
      `Translation for "${key}" not found in ${lang} language file`
    );
  };

  return (
    <LanguageContext.Provider value={{ lang, setLang, getTranslation }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
