import React, { useContext } from 'react'
import { LanguageContext } from '../../../context/languagecontext'

function ServicesOneData() {

  const {getTranslation} = useContext(LanguageContext)
 const data =  [
    {
      "id": 1,
      "icon": "building.png",
      "image": "service_details_1.jpg",
      "title": getTranslation("Office Security"),
      "excerpt": getTranslation("Office Securityparag"),
      "details": getTranslation("Office Securityparag"),
      "btnText": getTranslation("Continue Reading")
    },
    {
      "id": 2,
      "title": getTranslation("CCTV Security"),
      "icon": "surveillance.png",
      "image": "service_details_1.jpg",
      "excerpt":getTranslation("CCTV Securityparag") ,
      "details": getTranslation("CCTV Securityparag"),
      "btnText": getTranslation("Continue Reading")
    },
    {
      "id": 3,
      "title": getTranslation("Home Security"),
      "icon": "resort.png",
      "image": "service_details_1.jpg",
      "excerpt":  getTranslation("Home Securityparag"),
      "details":  getTranslation("Home Securityparag"),
       "btnText": getTranslation("Continue Reading")
    },
    {
      "id": 4,
      "title": getTranslation("Bank Security"),
      "icon": "dollar-coins.png",
      "image": "service_details_1.jpg",
      "excerpt": getTranslation("Bank Securityparag"),
      "details": getTranslation("Bank Securityparag"),
       "btnText": getTranslation("Continue Reading")
    },
    {
      "id": 5,
      "title": getTranslation("Bank Security"),
      "icon": "parking.png",
      "image": "service_details_1.jpg",
      "excerpt": getTranslation("Bank Securityparag"),
      "details": getTranslation("Bank Securityparag"),
       "btnText": getTranslation("Continue Reading")
    },
    {
      "id": 6,
      "title": getTranslation("Man Security"),
      "icon": "runer-silhouette-running-fast.png",
      "image": "service_details_1.jpg",
      "excerpt": getTranslation("Man Securityparag"),
      "details":  getTranslation("Man Securityparag"),
       "btnText": getTranslation("Continue Reading")
    }
  ]
  return data
}

export default ServicesOneData