
import React, { useContext } from 'react';
import { LanguageContext } from '../../context/languagecontext';

function UsePostTypeOneData() {
    const { getTranslation } = useContext(LanguageContext);
    const data = [
        {
            id: 1,
            image: "g1.jpg",
            title: getTranslation("Home Security"),
            details: getTranslation("Home Securityparag"),
            content: getTranslation("Home Securityparag"),
            btnText:  getTranslation("Read More"),
            btnLink: "/",
            date: getTranslation("24 May 2017"),
            categories: getTranslation("Home Security")
        },
        {
            id: 2,
            image: "g2.jpg",
            title: getTranslation("Personal Security"),
            details: getTranslation("Personal Securityparag"),
            content:  getTranslation("Personal Securityparag"),
            btnText:  getTranslation("Read More"),
            btnLink: "/",
            date: getTranslation("24 May 2017"),
            categories: getTranslation("Personal Security")
        },
        {
            id: 1,
            image: "g1.jpg",
            title: getTranslation("Cyber Security"),
            details: getTranslation("Cyber Securityparag"),
            content:  getTranslation("Cyber Securityparag"),
            btnText:  getTranslation("Read More"),
            btnLink: "/",
            date: getTranslation("24 May 2017"),
            categories: getTranslation("Cyber Security")
        },
        {
            id: 2,
            image: "g2.jpg",
            title: getTranslation("House Security for apartment"),
            details: getTranslation("Id detracto"),
            content: "Lorem ipsum dolor sit amet, vitae mattis vehicula scelerisque suscipit donec, tortor duis phasellus vivamus wisi",
            btnText:  getTranslation("Read More"),
            btnLink: "/",
            date: getTranslation("24 May 2017"),
            categories: getTranslation("House Security for apartment")
        },
        {
            id: 1,
            image: "g1.jpg",
            title: getTranslation("CCTV Security for apartment"),
            details: getTranslation("Id detracto"),
            content: "Lorem ipsum dolor sit amet, vitae mattis vehicula scelerisque suscipit donec, tortor duis phasellus vivamus wisi",
            btnText:  getTranslation("Read More"),
            btnLink: "/",
            date: getTranslation("24 May 2017"),
            categories: getTranslation("CCTV Security for apartment")
        },
        {
            id: 2,
            image: "g2.jpg",
            title: getTranslation("House Security for apartment"),
            details: getTranslation("Id detracto"),
            content: "Lorem ipsum dolor sit amet, vitae mattis vehicula scelerisque suscipit donec, tortor duis phasellus vivamus wisi",
            btnText:  getTranslation("Read More"),
            btnLink: "/",
            date: getTranslation("24 May 2017"),
            categories: getTranslation("House Security for apartment")
        },
     
       
   
    ];

    return data
   
}

export default UsePostTypeOneData;

