import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ServicesTwo from "./pages/services/ServicesTwo";
import ServiceDetailsTwo from "./pages/details-page/service-details/ServiceDetailsTwo";
import ServiceDetailsOne from "./pages/details-page/service-details/ServiceDetailsOne";
import ServicesOne from "./pages/services/ServicesOne";
import ProjectDetails from "./pages/details-page/ProjectDetails";
import ProjectsOne from "./pages/projects/ProjectsOne";
import ProjectsTwo from "./pages/projects/ProjectsTwo";
import ScrollToTop from "./common/ScrollToTop";
import TeamMembersPage from "./pages/TeamMembersPage";
import ContactUs from "./pages/ContactUs";
import AboutUsPage from "./pages/AboutUsPage";
import { LanguageContext } from "./context/languagecontext";

function App() {
  // const lang = localStorage.getItem("lang");
  // useEffect(() => {
  //   function setLoclaStorageLang() {
  //     return localStorage.getItem("lang") || localStorage.setItem("lang", "en");
  //   }
  //   setLoclaStorageLang();
  // }, [lang]);

  const { lang, getTranslation, setLang } = useContext(LanguageContext);

  console.log("lang", lang);

  return (
    <>
      <div
      
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={`${lang === "ar" ? "ar" : "en"} overflow-hidden`}
      >
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path={"/services-1"} element={<ServicesOne />} />
            <Route exact path={"/services-2"} element={<ServicesTwo />} />
            <Route
              exact
              path={"/service-details/:id"}
              element={<ServiceDetailsOne />}
            />
            <Route
              exact
              path={"/service-details-two/:id"}
              element={<ServiceDetailsTwo />}
            />
            <Route exact path={"/projects-1"} element={<ProjectsOne />} />
            <Route exact path={"/projects-2"} element={<ProjectsTwo />} />
            <Route
              exact
              path={"/project-details/:id"}
              element={<ProjectDetails />}
            />

          
          
            <Route exact path={"/employees"} element={<TeamMembersPage />} />
            <Route exact path={"/contact-us"} element={<ContactUs />} />
            <Route exact path={"/about"} element={<AboutUsPage />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
