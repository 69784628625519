import React, { useContext } from 'react'
import { LanguageContext } from '../../context/languagecontext'

function TeamMembersCardData() {
    const { getTranslation } =  useContext(LanguageContext)

 const data =    [
        {
            "id": 1,
            "image": "tm.jpg",
            "name": "Adalberto",
            "designation": getTranslation("Office Security"),
            "fbLink": "",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 2,
            "image": "tm2.jpg",
            "name": "Alejandro",
            "designation": getTranslation("House Security"),
            "fbLink": "/",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 3,
            "image": "tm3.jpg",
            "name": "Ambrose",
            "designation": getTranslation("Bank Security"),
            "fbLink": "/",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 4,
            "image": "tm4.jpg",
            "name": "Arnoldo",
            "designation": getTranslation("Parking Security"),
            "fbLink": "/",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 5,
            "image": "tm.jpg",
            "name": "Adalberto",
            "designation": getTranslation("Office Security"),
            "fbLink": "",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 6,
            "image": "tm2.jpg",
            "name": "Alejandro",
            "designation": getTranslation("House Security"),
            "fbLink": "/",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 7,
            "image": "tm3.jpg",
            "name": "Ambrose",
            "designation": getTranslation("Bank Security"),
            "fbLink": "/",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        },
        {
            "id": 8,
            "image": "tm4.jpg",
            "name": "Arnoldo",
            "designation": getTranslation("Parking Security"),
            "fbLink": "/",
            "twitterLink": "/",
            "linkedinLink": "/",
            "skypeLink": "/",
            "youtubeLink": "/"
        }
    ]
    return data
}

export default TeamMembersCardData

