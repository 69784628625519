// MenuComponent.js
import React, { useContext } from "react";
import { LanguageContext } from "../../context/languagecontext";

export const getMenuItems = () => {
  const { getTranslation } = useContext(LanguageContext);

  return [
    { id: 1, text: getTranslation("Home"), link: "/", dropdown: [] },
    {
      id: 2,
      text: getTranslation("Projects"),
      link: "#",
      dropdown: [
        {
          id: 2.1,
          text: getTranslation("Project 3 Column"),
          link: "/projects-1",
        },
        {
          id: 2.2,
          text: getTranslation("Project 4 Column"),
          link: "/projects-2",
        },
      ],
    },
    {
      id: 3,
      text: getTranslation("Service"),
      link: "#",
      dropdown: [
        { id: 3.1, text: getTranslation("Service 1"), link: "/services-1" },
        { id: 3.2, text: getTranslation("Service 2"), link: "/services-2" },
        {
          id: 3.3,
          text: getTranslation("Service Details 1"),
          link: "/service-details/1",
        },
        {
          id: 3.4,
          text: getTranslation("Service Details 2"),
          link: "/service-details-two/2",
        },
      ],
    },
    { id: 4, text: getTranslation("About"), link: "/about", dropdown: [] },
    {
      id: 5,
      text: getTranslation("Employees"),
      link: "/employees",
      dropdown: [],
    },
    {
      id: 7,
      text: getTranslation("Contact Us"),
      link: "/contact-us",
      dropdown: [],
    },
  ];
};
