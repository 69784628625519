import React, { useContext } from 'react'
import { LanguageContext } from '../../context/languagecontext'

function FaqCardData() {
   const {  getTranslation} = useContext(LanguageContext)
   const data = [
        {
            "id": 1,
            "title": getTranslation("Can I help"),
            "icon": "question.png",
            "content": getTranslation("text1")
        },
        {
            "id": 2,
            "title": getTranslation("Can I refund payment"),
            "icon": "question.png",
            "content": getTranslation("text2")
        },
        {
            "id": 3,
            "title": getTranslation("How many year's experience"),
            "icon": "question.png",
            "content": getTranslation("text3")
        }
    ]
    return data
}
export default FaqCardData
