import React, { useContext } from "react";
import WhyChooseUsCard from "./WhyChooseUsCard";
import Data from "./WhyChooseUsCardData";
import SectionTitle from "../section-title/SectionTitle";
import { LanguageContext } from "../../context/languagecontext";
import WhyChooseUsCardData from "./WhyChooseUsCardData";

const WhyChooseUs = () => {
  const { getTranslation, lang, setLang } = useContext(LanguageContext);

  const Data = WhyChooseUsCardData()
  return (
    <>
      {/* Why Choose Us Start */}
      <div
        className="why-choose why-choos-bg"
        style={
          lang === "ar"
            ? { backgroundImage: `url(/images/why-choose-bgmiror.png)` }
            : { backgroundImage: `url(/images/why-choose-bg.jpg)` }
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6" />
            <div className="col-md-6 why-choose-us-before">
              <div className="row">
                <SectionTitle title={ getTranslation("Why Choose Us")}  />
                {Data.slice(0, 4).map((item) => (
                  <WhyChooseUsCard key={item.id} data={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Why Choose Us End */}
    </>
  );
};

export default WhyChooseUs;
