import React, { useContext } from 'react';
import ServicesTypeOne from "../services/services-one/ServicesTypeOne";
import SectionTitle from "../section-title/SectionTitle";
import { LanguageContext } from '../../context/languagecontext';
import ServicesOneData from '../services/services-one/ServicesOneData';

const HomeServices = () => {
    const data =ServicesOneData()
    const { getTranslation} = useContext(LanguageContext);
       
    return (
        <>
            {/* Service Start */}
            <div className="service2-sec pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <SectionTitle
                                title={getTranslation("What We Offer Here")}
                                content={getTranslation("offerParagraph")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {
                            data.map((item) => (
                                <ServicesTypeOne
                                    key={item.id}
                                    data={item}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* Service End */}
        </>
    );
};

export default HomeServices;