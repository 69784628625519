import React from "react";
import Slider from "react-slick";
import "../../assets/css/slick.css";
import "../../assets/css/slick-theme.css";
import TestimonialCard from "./TestimonialCard";
import TestimonialSectionTitle from "../section-title/TestimonialSectionTitle";
import {TestimonialCarouselConfig} from "../../includes/scripts";
import { useContext } from "react";
import { LanguageContext } from "../../context/languagecontext";
import TestimonialData from "./TestimonialData";

const Testimonial = () => {
    const data =TestimonialData()
 const { getTranslation } =   useContext(LanguageContext)
    return (
        <>
            {/* Testimonial Section Start */}
            <div className="testimonial-sec pt-100 pb-100 "
                 style={{ backgroundImage:`url(/images/testimonial_bg.jpg)` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 ">
                            <TestimonialSectionTitle
                            subTitle={getTranslation("Our customers' opinions")}
                            title={getTranslation("ClientReview")}
                            content={getTranslation("client parag")}
                            btnText={getTranslation("See All")}
                            btnLink="/"     
                            />
                        </div> 
                        <div className="col-md-8 no-padding">
                            <Slider
                                className="all-testimonial2"
                                {...TestimonialCarouselConfig}
                            >
                                {
                                    data.map((item) => (
                                        <TestimonialCard
                                        key={item.id}
                                        data={item}
                                        />
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial Section End */}
        </>
    );
}

export default Testimonial;