import { useContext } from "react";
import { LanguageContext } from "../../context/languagecontext";

export const useSliderData = () => {
  const { getTranslation } = useContext(LanguageContext);

  const sliderData = [
    {
      id: 1,
      image: "slide1.jpg",
      title: getTranslation("Grow Up your business more securely"),
      content: getTranslation("Your protection is our priority - professional bodyguard services"),
      btnTextOne: getTranslation("CONTACT US"),
      btnLinkOne: "/",
      btnTextTwo: getTranslation("READ MORE"),
      btnLinkTwo: "/"
    },
    {
      id: 2,
      image: "slide2.jpg",
      title:getTranslation("Grow Up your business more securely"),
      content: getTranslation("Your protection is our priority - professional bodyguard services"),
      btnTextOne: getTranslation("CONTACT US"),
      btnLinkOne: "/",
      btnTextTwo: getTranslation("READ MORE"),
      btnLinkTwo: "/"
    }
  ];

  return sliderData;
};
